import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import HeaderTop from "../../components/HeaderTop";
import FormSub from "../../components/FormSub";
import FooterMid from "../../components/FooterMid";
import Helmet from "react-helmet";
import seoData from "../../data/seoData";
import SEO from "../../utils/SEO";

export default function OrderSuccess() {
  function hidefn() {
    let button = $(".order");
    if (!button.hasClass("animate")) {
      button.addClass("animate");
      setTimeout(() => {
        button.removeClass("animate");
        $(".orderall").addClass("hide");
      }, 10000);
    }
    let success1 = $(".success1");
    if (success1.hasClass("hide")) {
      setTimeout(() => {
        success1.removeClass("hide");
      }, 10000);
    }
  }
  const address = JSON.parse(localStorage.getItem("address"));

  const seo = seoData.orderSuccessPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      {window.innerWidth >= 830 ? (
        <header className="App-header border-bottom">
          <HeaderTop />
        </header>
      ) : (
        ""
      )}

      <main>
        <div className="text-center">
          <div className="orderall">
            <div>
              <img
                style={{ height: "64px" }}
                src="/waiting.png"
                className="img-fluid"
                alt="success"
              />
              <h5>.....</h5>
              <h1>Place</h1>
              <h1 className="mb-4">Your order now...</h1>
            </div>
            <div className="success2">
              <button
                aria-label="buy product"
                onClick={hidefn}
                className="order"
              >
                <span className="default">Complete Order</span>
                <span className="success">
                  Order Placed
                  <svg viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </svg>
                </span>
                <div className="box"></div>
                <div className="truck">
                  <div className="back"></div>
                  <div className="front">
                    <div className="window"></div>
                  </div>
                  <div className="light top"></div>
                  <div className="light bottom"></div>
                </div>
                <div className="lines"></div>
              </button>
              <a
                aria-label="place order"
                className="dribbble"
                href="https://dribbble.com/shots/7112021-Order-confirm-animation"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{
                    color: "white",
                  }}
                  alt=""
                  src="placeorder.mp4"
                />
              </a>
              <div className="mt-4">
                <h5>Billing Details</h5>
                <p>
                  {address?.name}
                  <br />
                  {address?.address1}
                  <br />
                  {address?.address2}
                </p>
              </div>
            </div>
          </div>

          <div className="success1 hide">
            <div className="success1">
              <img
                style={{ height: "120px" }}
                src="/tik.png"
                className="img-fluid"
                alt="success"
              />
              <h5>Awesome</h5>
              <h1>Congratulations.</h1>
              <h1 className="mb-4">Your order is accepted</h1>
              <div className="success2">
                <Link
                  aria-label="order completed"
                  className="success-btn"
                  to="/"
                >
                  Continue Shopping
                </Link>
                <p className="mt-4">
                  Have patience our customer executive will contact you soon
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <FormSub />
        <FooterMid />
      </footer>
    </>
  );
}
