// src/data/seoData.js
const seoData = {
  aboutPage: {
    title: "Best Furniture Shop in Kochi | A One Furniture",

    description:
      "A One Furniture is The Best Furniture Shop in Kochi. Top collection and best hospitality,A One Furniture N NagarP,O,Cherthala,Arookutty-688559 ",

    keywords: "Best Furniture Shop in Kochi",

    ogTitle: "A One Furniture | Best Furniture Shop in Kochi",

    ogDescription:
      "A One Furniture is The Best Furniture Shop in Kochi.Top qaulity and Top collection and best hospitality,A One Furniture N NagarP,O,Cherthala,Arookutty-688559 ",

    ogImage: "/images/logo/SFAlargeLogo.webp",

    ogUrl: "",

    twitterTitle: "A One Furniture | Best Furniture Shop in Kochi",

    twitterDescription:
      "A One Furniture is The Best Furniture Shop in Kochi.Top qaulity and Top collection and best hospitality,A One Furniture N NagarP,O,Cherthala,Arookutty-688559 ",

    twitterImage: "/images/logo/SFAlargeLogo.webp",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",

    cssPreload: "./AbouScreen.scss",

    locale: "en",
  },
  helpPage: {
    title: "Furniture Manufacturing Company | A One Furniture",

    description:
      "A One is the Best furniture manufacturing company in Kerala, top sofa set and chair collectionsA One Furniture N NagarP,O,Cherthala,Arookutty-688559",

    keywords: "Furniture Manufacturing Company",

    ogTitle: "Furniture Manufacturing Company | A One Furniture ",

    ogDescription:
      "A One is the Best furniture manufacturing company in Kerala, top sofa set and chair collectionsA One Furniture N NagarP,O,Cherthala,Arookutty-688559",

    ogImage: "/images/logo/SFAlargeLogo.webp",

    ogUrl: "",

    twitterTitle: "Furniture Manufacturing Company  | A One Furniture",

    twitterDescription:
      "A One is the Best furniture manufacturing company in Kerala, top sofa set and chair collectionsA One Furniture N NagarP,O,Cherthala,Arookutty-688559",

    twitterImage: "/images/logo/SFAlargeLogo.webp",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",

    cssPreload: "./AbouScreen.scss",

    locale: "en",
  },
  favouritePage: {
    title: "best furniture brand | A One Furniture",

    description:
      "Best Furniture Brand in Kerala, Buy Now Luxurious and Contemporary Furniture Sets at Competitive Prices. A One Furniture N NagarP.O,Cherthala, Arookutty-688559",

    keywords: "best furniture brand",

    ogTitle: "best furniture brand | A One Furniture",

    ogDescription:
      "Best Furniture Brand in Kerala, Buy Now Luxurious and Contemporary Furniture Sets at Competitive Prices. A One Furniture N NagarP.O,Cherthala, Arookutty-688559",

    ogImage: "/images/logo/SFAlargeLogo.webp",

    ogUrl: "",

    twitterTitle: "best furniture brand | A One Furniture",

    twitterDescription:
      "Best Furniture Brand in Kerala, Buy Now Luxurious and Contemporary Furniture Sets at Competitive Prices. A One Furniture N NagarP.O,Cherthala, Arookutty-688559",

    twitterImage: "/images/logo/SFAlargeLogo.webp",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",

    cssPreload: "",

    locale: "en",
  },
  homePage: {
    title: "Best Furniture Shop in Kerala | A One Furniture	",

    description:
      "A One Furniture's- Best Quality Furniture Shop in Kerala, We would recommend A One Furniture as one of the best in Kerala because of the positive experience and best servicing, A One Furniture N Nagar P,O,Cherthala,Arookutty-688559	",

    keywords: "Best Furniture Shop in Kerala",

    ogTitle: "Best Furniture Shop in Kerala | A One Furniture",

    ogDescription:
      "A One Furniture's- Best Quality Furniture Shop in Kerala, We would recommend A One Furniture as one of the best in Kerala because of the positive experience and best servicing, A One Furniture N Nagar P,O,Cherthala,Arookutty-688559	",

    ogImage: "/images/logo/SFAlargeLogo.webp",

    ogUrl: "",

    twitterTitle: "Best Furniture Shop in Kerala | A One Furniture",

    twitterDescription:
      "A One Furniture's- Best Quality Furniture Shop in Kerala, We would recommend A One Furniture as one of the best in Kerala because of the positive experience and best servicing, A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    twitterImage: "/images/logo/SFAlargeLogo.webp",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",

    cssPreload: "./HomeScreen.css",

    locale: "en",

    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  productsPage: {
    title: "A one Furniture | Furniture Stores Kochi",

    description:
      "A One Furniture is one of the most No1 furniture manufacturers in Kerala. We provide best quality customized furniture in Kerala,A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    keywords: "Furniture Stores Kochi",

    ogTitle: "A one Furniture | Furniture Stores Kochi",

    ogDescription:
      "A One Furniture is one of the most No1 furniture manufacturers in Kerala. We provide best quality customized furniture in Kerala,A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    ogImage: "",

    ogUrl: "",

    twitterTitle: "A one Furniture | Furniture Stores Kochi",

    twitterDescription:
      "A One Furniture is one of the most No1 furniture manufacturers in Kerala. We provide best quality customized furniture in Kerala,A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    twitterImage: "",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./Productscreen.scss",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  subProductsPage: {
    title: "premium furniture stores | A One Furniture",

    description:
      "Luxury Modern Furniture. Buy Premium Furniture at Best Prices. Wide Range of Contemporary Furniture Online Shopping. Shop for 		Designer Furniture Online. A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    keywords: "premium furniture stores",

    ogTitle: "premium furniture stores | A One Furniture",

    ogDescription:
      "Luxury Modern Furniture. Buy Premium Furniture at Best Prices. Wide Range of Contemporary Furniture Online Shopping. Shop for 		Designer Furniture Online. A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    ogImage: "",

    ogUrl: "",

    twitterTitle: "premium furniture stores | A One Furniture",

    twitterDescription:
      "Luxury Modern Furniture. Buy Premium Furniture at Best Prices. Wide Range of Contemporary Furniture Online Shopping. Shop for 		Designer Furniture Online. A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    twitterImage: "",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "Productscreen.scss",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  productDetailedPage: {
    title: "best furniture stores near me | A One Furniture	",

    description:
      "Furniture stores in near like  A One Furniture, best quality premium furniture suppliers in Kerala, A One Furniture N Nagar PO,Cherthala,Arookutty-688559 ",

    keywords: "best furniture stores near me",

    ogTitle: "best furniture stores near me | A One Furniture",

    ogDescription:
      "Furniture stores in near like  A One Furniture, best quality premium furniture suppliers in Kerala, A One Furniture N Nagar PO,Cherthala,Arookutty-688559",

    ogImage: "",

    ogUrl: "",

    twitterTitle: "best furniture stores near me | A One Furniture",

    twitterDescription:
      "Furniture stores in near like  A One Furniture, best quality premium furniture suppliers in Kerala, A One Furniture N Nagar PO,Cherthala,Arookutty-688559",

    twitterImage: "",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./query.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  orderSuccessPage: {
    title: "Furniture Manufacturers in Kerala | A One Furniture",

    description:
      "A One Furniture  is Top Furniture Manufacturers in Kerala. We manufacture premium  Royal Sofa Set, Wooden Sofa, Living Room Sofa,  	Dining Room Table. A One Furniture N Nagar P.O, Cherthala,Arookutty-688559",

    keywords: "Furniture Manufacturers in Kerala",

    ogTitle: "Furniture Manufacturers in Kerala | A One Furniture",

    ogDescription:
      "A One Furniture  is Top Furniture Manufacturers in Kerala. We manufacture premium  Royal Sofa Set, Wooden Sofa, Living Room Sofa,  	Dining Room Table. A One Furniture N Nagar P.O, Cherthala,Arookutty-688559",

    ogImage: "",

    ogUrl: "",

    twitterTitle: "Furniture Manufacturers in Kerala | A One Furniture",

    twitterDescription:
      "A One Furniture  is Top Furniture Manufacturers in Kerala. We manufacture premium  Royal Sofa Set, Wooden Sofa, Living Room Sofa,  	Dining Room Table. A One Furniture N Nagar P.O, Cherthala,Arookutty-688559",

    twitterImage: "",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./query.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  reviewPage: {
    title: "Best Quality Furniture in Kochi | A One Furniture",

    description:
      "A One Furniture is known for offering a wide range of high-quality furniture options. They typically focus on craftsmanship, 		durability, and stylish designs that cater to various tastes and preferences.	A One Furniture N Nagar P,O,Cherthala,Arookutty-688559	",

    keywords: "Best Quality Furniture in Kochi",

    ogTitle: "Best Quality Furniture in Kochi | A One Furniture",

    ogDescription:
      "A One Furniture is known for offering a wide range of high-quality furniture options. They typically focus on craftsmanship, 		durability, and stylish designs that cater to various tastes and preferences.	A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    ogImage: "",

    ogUrl: "",

    twitterTitle: "Best Quality Furniture in Kochi | A One Furniture",

    twitterDescription:
      "A One Furniture is known for offering a wide range of high-quality furniture options. They typically focus on craftsmanship, 		durability, and stylish designs that cater to various tastes and preferences.	A One Furniture N Nagar P,O,Cherthala,Arookutty-688559",

    twitterImage: "",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./ReviewPage.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  searchPage: {
    title: "living room couch sets | A One Furniture",

    description:
      "A One Furniture offers a variety of living room couch sets that combine style, comfort, and durability. Many sets may offer 		customization in terms of fabric, color, and configuration, allowing you to tailor the furniture to your specific needs.",

    keywords: "living room couch sets",

    ogTitle: "living room couch sets | A One Furniture",

    ogDescription:
      "A One Furniture offers a variety of living room couch sets that combine style, comfort, and durability. Many sets may offer 		customization in terms of fabric, color, and configuration, allowing you to tailor the furniture to your specific needs.",

    ogImage: "",

    ogUrl: "",

    twitterTitle: "living room couch sets | A One Furniture",

    twitterDescription:
      "A One Furniture offers a variety of living room couch sets that combine style, comfort, and durability. Many sets may offer 		customization in terms of fabric, color, and configuration, allowing you to tailor the furniture to your specific needs.",

    twitterImage: "",

    canonicalUrl: "",

    favicon: "/images/logo/SFAsmallLogo.webp",
    cssPreload: "./searchScreen.css",
    locale: "en",
    productStructuredData: {
      "@context": "http://schema.org",
      "@type": "Product",
      name: "",
      image: "",
      description: "",
      brand: {
        "@type": "Brand",
        name: "Aone Furniture",
      },
      offers: {
        "@type": "Offer",
        priceCurrency: "INR",
        price: "",
        itemCondition: "",
        availability: "",
        url: "",
      },
    },
  },
  // Add more pages as needed
};

export default seoData;
