import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../../hooks/useFetch";
import "./typeListScreen.scss";
import axios from "axios";
import { apiStart } from "../../../apiurl/apiData";

export default function TypeListScreen() {
  const navigate = useNavigate();

  const { type } = useParams();
  const { data } = useFetch(`${apiStart}/api/${type}/`);
  const handleclick = (e) => {
    navigate(`/admin/${type}/${e}`);
  };
  const handleDelete = async (e) => {
    await axios.delete(`${apiStart}/api/${type}/${e}/`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  return (
    <Container fluid className="home">
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9} className="homeContainer">
          <Card
            className="m-3"
            onClick={() => {
              navigate("new");
            }}
          >
            <Card.Body className="text-center">
              <AddIcon />
              <br />
              Add New
            </Card.Body>
          </Card>
          <h5>Edit & Delete Products</h5>

          <div className="product-admin-align">
            {data?.map((e) => (
              <Card className="text-center">
                <Card.Body>
                  <h5>{e.name}</h5>
                  <Card.Img
                    src={e.images[0]}
                    alt="imagehere"
                    className="img-fluid"
                  />

                  <Button
                    aria-label="edit"
                    onClick={() => handleclick(e._id)}
                    className="m-2"
                    variant="success"
                  >
                    Edit
                  </Button>
                  <Button
                    aria-label="delete"
                    onClick={() => handleDelete(e._id)}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
