import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useFetch from "../../../hooks/useFetch";
import "./productListScreen.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import { apiStart } from "../../../apiurl/apiData";

export default function ProductListScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [category, setCategory] = useState("");

  const { data } = useFetch(`${apiStart}/api/products/allProduct/${category}`);

  const handleclick = (e) => {
    navigate(`/admin/products/${e}`);
  };
  const handledelete = async (e) => {
    await axios.delete(`${apiStart}/api/products/${e}/`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  const handleNavigate = (type) => {
    query.set("category", type);
    window.location.search = query.toString();
  };
  useEffect(() => {
    let queryCategory = query.get("category");
    setCategory(queryCategory);
  }, [query]);
  return (
    <Container fluid className="home">
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9} className="homeContainer">
          <Card
            className="m-3"
            onClick={() => {
              navigate(`new?category=${category}`);
            }}
          >
            <Card.Body className="text-center">
              <AddIcon />
              <br />
              Add New
            </Card.Body>
          </Card>
          <h5>Edit & Delete Products</h5>
          <div className="p-2">
            <button
              aria-label="sofa sets"
              onClick={() => {
                handleNavigate("sofa-sets");
              }}
              className="prod-name"
            >
              Sofa Sets
            </button>
            <button
              aria-label="beds"
              onClick={() => handleNavigate("beds")}
              className="prod-name"
            >
              Beds
            </button>
            <button
              aria-label="table"
              onClick={() => handleNavigate("table")}
              className="prod-name"
            >
              Table
            </button>
            <button
              aria-label="recliner sofa"
              onClick={() => handleNavigate("recliner-sofa")}
              className="prod-name"
            >
              Recliner Sofa
            </button>
            <button
              aria-label="wardrobes"
              onClick={() => handleNavigate("wardrobes")}
              className="prod-name"
            >
              Wardrobes
            </button>
            <button
              aria-label="office table"
              onClick={() => handleNavigate("office-table")}
              className="prod-name"
            >
              Office Table
            </button>
            <button
              aria-label="grandfather clock"
              onClick={() => handleNavigate("grandfather-clock")}
              className="prod-name"
            >
              Grandfather Clock
            </button>
            <button
              aria-label="chair dining set"
              onClick={() => handleNavigate("chair-dining-set")}
              className="prod-name"
            >
              Chair Dining Set
            </button>
            <button
              aria-label="book shelves"
              onClick={() => handleNavigate("book-shelves")}
              className="prod-name"
            >
              Book Shelves
            </button>
            <button
              aria-label="coffee table"
              onClick={() => handleNavigate("coffee-table")}
              className="prod-name"
            >
              Coffee Table
            </button>
            <button
              aria-label="carpet"
              onClick={() => handleNavigate("carpet")}
              className="prod-name"
            >
              Carpet
            </button>
            <button
              aria-label="tv stand"
              onClick={() => handleNavigate("tv-stand")}
              className="prod-name"
            >
              Tv Stand
            </button>
            <button
              aria-label="home decor"
              onClick={() => handleNavigate("home-decor")}
              className="prod-name"
            >
              Home Decor
            </button>
            <button
              aria-label="lamps & lights"
              onClick={() => handleNavigate("lamps-&-lighting")}
              className="prod-name"
            >
              Lamps & Lighting
            </button>
            <button
              aria-label="crockery shelf"
              onClick={() => handleNavigate("crokery-shelf")}
              className="prod-name"
            >
              Crokery Shelf
            </button>
            <button
              aria-label="bed side table"
              onClick={() => handleNavigate("bed-side-table")}
              className="prod-name"
            >
              Bed Side Table
            </button>
            <button
              aria-label="shoe rack"
              onClick={() => handleNavigate("shoe-rack")}
              className="prod-name"
            >
              Shoe Rack
            </button>
            <button
              aria-label="dressing tables"
              onClick={() => handleNavigate("dressing-tables")}
              className="prod-name"
            >
              Dressing Tables
            </button>
            <button
              aria-label="modular kitchen"
              onClick={() => handleNavigate("modular-kitchen")}
              className="prod-name"
            >
              Modular Kitchen
            </button>
            <button
              aria-label="stool"
              onClick={() => handleNavigate("stool")}
              className="prod-name"
            >
              Stool
            </button>
            <button
              aria-label="sitout chair"
              onClick={() => handleNavigate("sitout-chair")}
              className="prod-name"
            >
              Sitout Chair
            </button>
            <button
              aria-label="diwan"
              onClick={() => handleNavigate("diwan")}
              className="prod-name"
            >
              Diwan
            </button>
            <button
              aria-label="bench"
              onClick={() => handleNavigate("bench")}
              className="prod-name"
            >
              Bench
            </button>
            <button
              aria-label="display unit"
              onClick={() => handleNavigate("dispaly-unit")}
              className="prod-name"
            >
              Dispaly unit
            </button>
          </div>
          <div className="product-admin-align">
            {data?.map((e) => (
              <Card className="text-center">
                <Card.Body>
                  <Card.Img
                    src={e.images[0]}
                    alt="imagehere"
                    className="img-fluid"
                  />
                  {!e.name ? (
                    ""
                  ) : e.name === "" ? (
                    ""
                  ) : (
                    <Card.Body className="p-o m-0">{e.name}</Card.Body>
                  )}

                  <Button
                    aria-label="Edit product"
                    onClick={() => handleclick(e._id)}
                    className="m-2"
                    variant="success"
                  >
                    Edit
                  </Button>
                  <Button
                    aria-label="delete product"
                    onClick={() => handledelete(e._id)}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
