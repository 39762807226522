import "./new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";
import Gallery from "react-image-gallery"; // Import the Gallery component
import "react-image-gallery/styles/css/image-gallery.css"; // Import gallery styles

const NewProduct = ({ inputs, title }) => {
  const categoryOptions = [
    "sofa-sets",
    "beds",
    "table",
    "recliner-sofa",
    "wardrobes",
    "office-table",
    "grandfather-clock",
    "chair-dining-set",
    "book-shelves",
    "coffee-table",
    "carpet",
    "tv-stand",
    "home-decor",
    "lamps-&-lighting",
    "crockery-shelf",
    "bed-side-table",
    "shoe-rack",
    "dressing-tables",
    "modular-kitchen",
    "stool",
    "sitout-chair",
    "diwan",
    "bench",
    "display-unit",
  ];

  const subCategoryOptions = [
    "living",
    "bedroom",
    "dining",
    "storage",
    "kidsroom",
    "decor",
    "furnishing",
    "outdoor",
  ];

  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [category, setCategory] = useState(categoryOptions[0]);
  const [subCategory, setSubCategory] = useState(subCategoryOptions[0]);
  const [name, setName] = useState("");
  console.log(files);

  // Handle multiple file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to array
    setFiles([...files, ...selectedFiles]);
  };

  const handleRemoveImage = (index) => {
    // Remove newly added images
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    // Append each selected file to FormData
    files.forEach((file) => {
      formData.append("images", file); // Assuming the backend accepts "images" key
    });
    formData.append("category", category);
    formData.append("subCategory", subCategory);
    formData.append("name", name);

    await axios.post(`${apiStart}/api/products/`, formData, {
      withCredentials: true,
    });

    window.location.href = `/admin/products?category=${category}`;
  };

  // Map files to the format expected by react-image-gallery
  const galleryImages = files.map((file, index) => ({
    original: URL.createObjectURL(file),
    thumbnail: URL.createObjectURL(file),
    renderItem: () => (
      <div className="d-flex align-items-center justify-content-center image-preview">
        <img
          style={{ width: "450px" }}
          src={URL.createObjectURL(file)}
          alt="Preview"
        />
        <button
          style={{ position: "absolute", bottom: 0 }}
          type="button"
          className="remove-btn py-3 rounded bg-white"
          onClick={() => handleRemoveImage(index)}
        >
          Remove
        </button>
      </div>
    ),
  }));

  return (
    <Container fluid className="home">
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9} className="newContainer">
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              {galleryImages.length > 0 ? (
                <Gallery items={galleryImages} /> // Display images with react-image-gallery
              ) : (
                <img
                  src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  alt="placeholder"
                  className="img-fluid"
                />
              )}
            </div>
            <div className="right">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="formInput">
                  <Form.Label htmlFor="file">
                    Image upload:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </Form.Label>
                  <Form.Control
                    id="file"
                    type="file"
                    name="file"
                    multiple // Enable multiple file selection
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Category:</Form.Label>
                  <Form.Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {categoryOptions.map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sub Category:</Form.Label>
                  <Form.Select
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    {subCategoryOptions.map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="text"
                    name="text"
                    placeholder="Enter product name"
                  />
                </Form.Group>

                <Button aria-label="new product" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewProduct;
