import React from "react";
import { Container } from "react-bootstrap";
import Helmet from "react-helmet";
import FooterMid from "../../components/FooterMid";
import FooterTop from "../../components/FooterTop";
import FormSub from "../../components/FormSub";
import HeaderLow from "../../components/HeaderLow";
import HeaderMid from "../../components/HeaderMid";
import HeaderTop from "../../components/HeaderTop";
import MobileHead from "../../components/MobileHead";
import SEO from "../../utils/SEO";
import seoData from "../../data/seoData";

export default function Help() {
  const seo = seoData.helpPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
      />

      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>
      <main>
        <Container
          className="text-start aboutaone border"
          style={{ fontFamily: "pangramregular" }}
        >
          <h1>
            <span className="stroke-head">Contact Us</span>
          </h1>
          <h6 style={{ color: "#ed7745" }}>Call Us</h6>
          <p>
            For product, order related queries, and pretty much anything else,
            get in touch with us at +91 9072777555
          </p>

          <h6 style={{ color: "#ed7745" }}>Email</h6>
          <p>
            For product, order related queries, and pretty much anything else,
            write to aonefrunitures@gmail.com
          </p>

          <h6 style={{ color: "#ed7745" }}>Bulk Orders</h6>
          <p>
            If you are a property manager, an architect, builder, designer or
            any business enterprise looking to buy in bulk for your hotel,
            office or any other property, we'd love to help! Email us at the
            same email id as below aonefrunitures@gmail.com
          </p>

          <h6 style={{ color: "#ed7745" }}>Technology</h6>
          <p>
            For any issues or feedback with our website, write to
            aonefrunitures@gmail.com
          </p>
          <p>
            For sharing any security related issues or concerns, write to
            aonefrunitures@gmail.com
          </p>
          <hr />
        </Container>
      </main>
      <footer>
        <FormSub />
        <FooterTop />
        <FooterMid />
      </footer>
    </>
  );
}
