import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../../store/Actions/Admin";

const Sidebar = () => {
  const dispatch = useDispatch();
  const handleClick = async () => {
    await dispatch(logoutAdmin());
    window.location.reload();
  };
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Aone Furniture</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/admin">
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link
            to="/admin/products?category=sofa-sets"
            style={{ textDecoration: "none" }}
          >
            <li>
              <StoreIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <Link to="/admin/bestSeller" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Best Seller</span>
            </li>
          </Link>
          <Link to="/admin/newLaunch" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>New Launch</span>
            </li>
          </Link>
          <Link to="/admin/orders">
            <li>
              <CreditCardIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>

          <p className="title">OTHERS</p>
          <Link to="/admin/subscriptions">
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Subscriptions</span>
            </li>
          </Link>
          <Link to="/admin/feedback">
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Feedbacks</span>
            </li>
          </Link>
          <li onClick={handleClick}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
