import "./edit.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { typeListInputs } from "../../../../formSource";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";
import Gallery from "react-image-gallery"; // Import the Gallery component
import "react-image-gallery/styles/css/image-gallery.css"; // Import gallery styles

const Edit = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { id } = useParams();
  const { data } = useFetch(`${apiStart}/api/${type}/${id}`);

  const [files, setFiles] = useState([]);
  const [keptImages, setKeptImages] = useState([]); // Images to keep (existing)
  const [removedImages, setRemovedImages] = useState([]);
  const [text, setText] = useState("");

  // Handle multiple file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prev) => [...prev, ...selectedFiles]);
  };

  const handleRemoveImage = (index, isNew = false) => {
    if (isNew) {
      // Remove from newly uploaded images
      setFiles((prev) => prev.filter((_, i) => i !== index));
    } else {
      // Remove from existing images
      const removedImage = keptImages[index];
      setKeptImages((prev) => prev.filter((_, i) => i !== index));
      setRemovedImages((prev) => [...prev, removedImage]);
    }
  };
  console.log(keptImages);

  const handleClick = async () => {
    if (files.length === 0 && text !== "") {
      await axios.put(
        `${apiStart}/api/${type}/${id}`,
        {
          name: text,
        },
        {
          withCredentials: true,
        }
      );
    } else if (files.length > 0 && text === "") {
      let formData = new FormData();
      // Append each selected file to FormData
      files.forEach((file) => {
        formData.append("images", file);
      });
      formData.append("keptImages", JSON.stringify(keptImages));
      formData.append("removedImages", JSON.stringify(removedImages));
      formData.append("text", data.name);
      await axios.put(`${apiStart}/api/${type}/img/${id}`, formData, {
        withCredentials: true,
      });
    } else if (files.length > 0 && text !== "") {
      let formData = new FormData();
      // Append each selected file to FormData
      files.forEach((file) => {
        formData.append("images", file);
      });
      formData.append("keptImages", JSON.stringify(keptImages));
      formData.append("removedImages", JSON.stringify(removedImages));
      formData.append("text", text);
      await axios.put(`${apiStart}/api/${type}/img/${id}`, formData, {
        withCredentials: true,
      });
    }

    window.location.href = `/admin/${type}`;
  };

  // Map files to the format expected by react-image-gallery
  const galleryImages = [
    ...keptImages.map((image, index) => ({
      original: image,
      thumbnail: image,
      renderItem: () => (
        <div className="d-flex align-items-center justify-content-center image-preview">
          <img style={{ width: "50%" }} src={image} alt={`Preview ${index}`} />
          <Button
            style={{ position: "absolute", bottom: 0 }}
            variant="danger"
            size="sm"
            className="remove-button"
            onClick={() => handleRemoveImage(index, false)}
          >
            Remove
          </Button>
        </div>
      ),
    })),
    ...files.map((file, index) => ({
      original: URL.createObjectURL(file),
      thumbnail: URL.createObjectURL(file),
      renderItem: () => (
        <div className="d-flex align-items-center justify-content-center image-preview">
          <img
            style={{ width: "50%" }}
            src={URL.createObjectURL(file)}
            alt={`Preview new ${index}`}
          />
          <Button
            style={{ position: "absolute", bottom: 0 }}
            variant="danger"
            size="sm"
            className="remove-button"
            onClick={() => handleRemoveImage(index, true)}
          >
            Remove
          </Button>
        </div>
      ),
    })),
  ];

  useEffect(() => {
    if (data.images) setKeptImages(data.images); // Populate keptImages when data is loaded
  }, [data.images]);
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="top">
          <h1>Edit {type} Products</h1>
        </div>
        <div className="main-set">
          <div className="left">
            {galleryImages?.length > 0 ? (
              <Gallery items={galleryImages} /> // Display images with react-image-gallery
            ) : (
              <img
                src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                alt="placeholder"
                className="img-fluid"
              />
            )}
          </div>
          <div className="right">
            <Form onSubmit={handleClick}>
              <Form.Group className="formInput">
                <Form.Label htmlFor="file">
                  Image upload:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </Form.Label>
                <Form.Control
                  id="file"
                  type="file"
                  name="file"
                  multiple // Enable multiple file selection
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Form.Group>

              <div className="" key={typeListInputs[0].id}>
                <Form.Group className="mb-3">
                  <Form.Label>{typeListInputs[0].label}</Form.Label>
                  <Form.Control
                    defaultValue={data.name}
                    type="text"
                    placeholder="Enter your product name"
                    onChange={(e) => setText(e.target.value)}
                  />
                </Form.Group>
              </div>
              {text === "" && files.length === 0 ? (
                <Button aria-label="edit product" disabled type="submit">
                  Submit
                </Button>
              ) : (
                <Button aria-label="edit product" type="submit">
                  Submit
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
