import { Button, Card, ToastContainer } from "react-bootstrap";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterMid from "../../components/FooterMid";
import FooterTop from "../../components/FooterTop";
import FormSub from "../../components/FormSub";
import HeaderLow from "../../components/HeaderLow";
import HeaderMid from "../../components/HeaderMid";
import HeaderTop from "../../components/HeaderTop";
import MobileHead from "../../components/MobileHead";
import { useDispatch } from "react-redux";
import "../homescreen/HomeScreen.css";
import { removeFromfavourite } from "../../store/Actions/Favourite";
import SEO from "../../utils/SEO";
import seoData from "../../data/seoData";

export default function FavouriteScreen() {
  const dispatch = useDispatch();
  const notify = () => toast(`Removed from WishList!`);
  const navigate = useNavigate();
  const removeFromFavHandler = (e, y) => {
    y.target.parentElement.classList.add("hide");
    notify();
    dispatch(
      removeFromfavourite({
        id: e.id,
      })
    );
  };

  const goOnHandler = (e) => {
    if (e.image.split("/")[2] === "bestseller") {
      navigate(`/query/bestseller/${e.id}`);
    }
    if (e.image.split("/")[2] === "newlaunch") {
      navigate(`/query/newlaunch/${e.id}`);
    }
    if (e.image.split("/")[2] === "categories") {
      navigate(`/query/${e.id}`);
    }
  };

  const existingFavItems = JSON.parse(localStorage.getItem("favItems"));

  const seo = seoData.favouritePage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>
      <main>
        <div className="text-center" style={{ fontFamily: "pangramregular" }}>
          <h3>
            <span className="stroke-head">Favourite Screen</span>
          </h3>
          <div style={{ backgroundColor: "#e2e1d963" }} className=" p-3">
            <div className="bst-seller ">
              <div className="fav-align">
                {existingFavItems?.map((e) => (
                  <Card data-id={e.id} className="pic-hover mb-3 cont-icon">
                    <i
                      style={{ color: "red", backgroundColor: "orange" }}
                      onClick={(y) => removeFromFavHandler(e, y)}
                      className="fa fa-heart icon-fav"
                      aria-hidden="true"
                    ></i>{" "}
                    <div
                      onClick={() => goOnHandler(e)}
                      style={{ color: "black" }}
                    >
                      <Card.Img
                        className="img-fluid bstimgsize"
                        variant="top"
                        src={e.image}
                        alt="fav imgs"
                      />
                      <Card.Body className="p-0 mt-1">
                        <p>{e.name ? e.name : ""}</p>
                      </Card.Body>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
            <a aria-label="home" style={{ color: "white" }} href="/">
              <Button aria-label="add product to favourite">
                Add more favourite items
              </Button>
            </a>
          </div>
        </div>
      </main>
      <footer>
        <FormSub />
        <FooterTop />
        <FooterMid />
      </footer>
    </>
  );
}
