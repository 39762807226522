import Slide from "./components/Slide";
import { Container, Col, Row } from "react-bootstrap";
import Categories from "./components/Categories";
import BestSellers from "./components/BestSellers";
import NewLauches from "./components/NewLauches";
import "react-toastify/dist/ReactToastify.css";
import HeaderTop from "../../components/HeaderTop";
import HeaderMid from "../../components/HeaderMid";
import HeaderLow from "../../components/HeaderLow";
import MobileHead from "../../components/MobileHead";
import FormSub from "../../components/FormSub";
import FooterTop from "../../components/FooterTop";
import FooterMid from "../../components/FooterMid";
import { Helmet } from "react-helmet";
import Social from "./components/Social";
import CustomerStories from "./components/CustomerStories";
import "./HomeScreen.css";
import seoData from "../../data/seoData";
import SEO from "../../utils/SEO";

export default function HomeScreen() {
  const seo = seoData.homePage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <header className="App-header">
        {window.innerWidth >= 780 ? (
          <>
            <HeaderTop />
            <HeaderMid />
            <HeaderLow />
          </>
        ) : (
          <MobileHead />
        )}
      </header>

      <main className="mt-3">
        <Slide />
        <Container fluid>
          <Col>
            <Row>
              <Categories />
            </Row>
            <Row className="supreme-home">
              <NewLauches />
              <hr />
              <div className="d-flex justify-content-center">
                <img
                  className="ad-img img-fluid"
                  src="/images/ads.png"
                  alt="advertisements"
                  loading="lazy"
                />
              </div>
              <hr />
              <BestSellers data="Best Sellers" />
            </Row>
          </Col>
        </Container>
      </main>
      <footer>
        <FormSub />
        <CustomerStories />
        <div>
          <FooterTop />
          <FooterMid />
          <Social />
        </div>
      </footer>
    </>
  );
}
