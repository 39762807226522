import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterTitle,
  twitterDescription,
  twitterImage,
  canonicalUrl,
  favicon,
  cssPreload,
  productStructuredData,
  locale,
}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Open Graph */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl} />
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImage} />
      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />
      {/* Language and Locale */}
      <meta http-equiv="content-language" content={locale || "en"} />
      {/* Links */}
      <link rel="icon" type="image/png" href={favicon} />
      <link rel="preload" as="style" href={cssPreload} />
      <link rel="canonical" href={canonicalUrl} />
      {/* Hreflang for multiple languages (if applicable) */}
      <link rel="alternate" hreflang={locale || "en"} href={canonicalUrl} />
      {/* Structured Data for WebPage */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "WebPage",
          name: title,
          description: description,
          url: canonicalUrl,
        })}
      </script>
      {/* Structured Data for Products (if applicable) */}
      {productStructuredData && (
        <script type="application/ld+json">
          {JSON.stringify(productStructuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
