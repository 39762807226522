import React, { useEffect, useState } from "react";
import "./ReviewPage.css";
import { apiStart } from "../../apiurl/apiData";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import SEO from "../../utils/SEO";
import seoData from "../../data/seoData";

export default function ReviewPage() {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [feed, setFeed] = useState("");
  const changeFeed = { feedback: feed };

  const handleFeedback = async (e) => {
    e.preventDefault();
    try {
      const data = await axios.post(`${apiStart}/api/feed/`, changeFeed);
      setFeed("");
      setSuccess(true);
    } catch (err) {
      console.log("not worked");
    }
  };

  const seo = seoData.reviewPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      <div className="reviewContainer">
        <div className="reviewCont1">
          <img className="img-fluid" src="/reviewBg.webp" alt="" />
          {success ? (
            <div className="p-3">
              <h1
                style={{ fontWeight: "bold", fontSize: "25px" }}
                className="d-flex align-items-center mb-3"
              >
                <img
                  className="mr-2"
                  style={{ width: "40px" }}
                  src="/logo.png"
                  alt=""
                />
                Thank you!
              </h1>
              <p className="mb-2">
                Your feedback will help us improve our product & service.
              </p>
              <div className="d-flex flex-column">
                <p className="my-4">
                  If you like our products, please leave a review in{" "}
                  <a
                    target="_blank"
                    style={{ color: "blue" }}
                    href="https://search.google.com/local/writereview?placeid=ChIJud5q3QNxCDsRzG7Sll58Z9A"
                  >
                    google review
                  </a>
                </p>
                <Button
                  style={{ background: "black", color: "white" }}
                  className="px-5"
                  variant=""
                  onClick={() => navigate("/")}
                >
                  Done
                </Button>
              </div>
            </div>
          ) : (
            <div className="py-3">
              <h1
                style={{ fontWeight: "bold", fontSize: "25px" }}
                className="d-flex align-items-center mb-3"
              >
                <img
                  className="mr-2"
                  style={{ width: "40px" }}
                  src="/logo.png"
                  alt=""
                />
                Your Experience
              </h1>
              <p className="mb-2">Your opinion matter to us!</p>
              <Form onSubmit={handleFeedback}>
                <Form.Group>
                  <Form.Control
                    className="reviewControl"
                    onChange={(e) => setFeed(e.target.value)}
                    as="textarea"
                    rows={4}
                    placeholder="Let us know here..."
                    required
                  />
                </Form.Group>
                <Button
                  style={{ background: "black", color: "white" }}
                  variant=""
                  type="submit"
                >
                  Continue
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
