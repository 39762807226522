import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSub from "../../components/FormSub";
import FooterMid from "../../components/FooterMid";
import HeaderTop from "../../components/HeaderTop";
import "./query.css";
import Helmet from "react-helmet";
import { Form, Button } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import $ from "jquery";
import { apiStart } from "../../apiurl/apiData";
import seoData from "../../data/seoData";
import SEO from "../../utils/SEO";
import { FaWhatsapp } from "react-icons/fa";
import Gallery from "react-image-gallery"; // Import the Gallery component
import "react-image-gallery/styles/css/image-gallery.css"; // Import gallery styles

export default function QueryScreen() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [address1, setAddress1] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");

  const params = useParams();
  const { id } = params;
  const { data } = useFetch(`${apiStart}/api/products/${id}`);

  const allDataInput = {
    name: name,
    email: email,
    number: parseInt(number),
    pincode: parseInt(postalcode),
    address1: address1,
    address2: city,
    address3: landmark,
    image: data.image,
  };

  const handleClick = async (e) => {
    e.preventDefault();
    $("#orderSubmit").html("Loading...");
    $("#orderSubmit").prop("disabled", true);
    try {
      localStorage.setItem("address", JSON.stringify(allDataInput));
      const res = await axios.post(`${apiStart}/api/order/`, allDataInput, {
        withCredentials: true,
      });
      if (res.data) {
        navigate("/query/success");
      } else {
        console.log("failed");
      }
    } catch (err) {
      console.log("mot worked");
    }
  };

  // Map files to the format expected by react-image-gallery
  const galleryImages = Array.isArray(data.images)
    ? data.images?.map((file) => ({
        original: file,
        thumbnail: file,
      }))
    : [];

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  const seo = seoData.productDetailedPage;
  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ogTitle={seo.ogTitle}
        ogDescription={seo.ogDescription}
        ogImage={seo.ogImage}
        ogUrl={seo.ogUrl}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        canonicalUrl={seo.canonicalUrl}
        favicon={seo.favicon}
        cssPreload={seo.cssPreload}
        locale={seo.locale}
        productStructuredData={seo.productStructuredData}
      />

      {window.innerWidth >= 830 ? (
        <header className="App-header border-bottom">
          <HeaderTop />
        </header>
      ) : (
        ""
      )}
      <main>
        <div className="mainquery pt-5 pb-5" style={{ background: "#f9f8f2" }}>
          <div
            style={{ background: "white" }}
            className="container mb-2 border p-3"
          >
            <h4 className="border-bottom">order summary</h4>
            <div className="spec-data">
              <Gallery
                items={galleryImages}
                showThumbnails={true}
                showFullscreenButton={false}
                showBullets={false}
                showPlayButton={false}
                renderItem={(item) => (
                  <img
                    src={item.original}
                    alt=""
                    style={{ width: "740px", height: "auto" }} // Adjust width and keep aspect ratio
                    className="img-fluid"
                  />
                )}
              />
              {data.name && (
                <h1
                  className="bg-success text-center"
                  style={{ width: "100%", color: "white" }}
                >
                  {data.name}
                </h1>
              )}
            </div>
          </div>

          <div className="mx-2 mb-5">
            <div
              style={{
                background: "#25D366",
                fontWeight: "bolder",
                cursor: "pointer",
                fontSize: "16px",
                padding: "3px 0px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              }}
              className="container text-white rounded text-center py-1"
              onClick={() => {
                const currentUrl = window.location.href; // Get the current URL
                const message = `Hello!%20I%20would%20like%20to%20place%20an%20order%20for%20the%20product.%20Here%20is%20the%20link%20for%20reference:%20${encodeURIComponent(
                  currentUrl
                )}.%20Looking forward to your help!`;
                window.open(`https://wa.me/+919072777555?text=${message}`);
              }}
            >
              <FaWhatsapp style={{ width: "30px" }} className="ml-1" /> ORDER
              THROUGH WHATSAPP
            </div>
          </div>

          <div
            style={{ background: "white" }}
            className="formquery container border p-3 mt-2"
          >
            <h4 className="border-bottom">Delivery & Billing Address</h4>
            <Form className="row g-3">
              <Form.Group>
                <Form.Label>
                  Full Name{" "}
                  {name === "" ? <span style={{ color: "red" }}>*</span> : ""}
                </Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Email address{" "}
                  {email === "" ? <span style={{ color: "red" }}>*</span> : ""}
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Contact Number{" "}
                  {number === "" ? <span style={{ color: "red" }}>*</span> : ""}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="+91"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Postal Code{" "}
                  {postalcode === "" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  type="number"
                  value={postalcode}
                  onChange={(e) => setPostalcode(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Address{" "}
                  {address1 === "" || city === "" || landmark === "" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                </Form.Label>
                <Form.Control
                  placeholder="Address line 1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  placeholder="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  placeholder="Landmark"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                  style={{ height: "35px" }}
                  required
                />
              </Form.Group>
              <Form.Group>
                <div className="mb-3">
                  <Button
                    id="orderSubmit"
                    aria-label="address submit"
                    style={{ width: "100%", fontWeight: "bold" }}
                    type="submit"
                    className="btn btn-warning"
                    variant="primary"
                    onClick={handleClick}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </main>
      <footer>
        <FormSub />
        <FooterMid />
      </footer>
    </>
  );
}
