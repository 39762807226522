import "./new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";
import Gallery from "react-image-gallery"; // Import the Gallery component
import "react-image-gallery/styles/css/image-gallery.css"; // Import gallery styles

const NewType = ({ inputs, title }) => {
  const navigate = useNavigate();
  const { type } = useParams();

  const [files, setFiles] = useState([]);
  const [text, setText] = useState("");

  // Handle multiple file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to array
    setFiles([...files, ...selectedFiles]);
  };

  const handleRemoveImage = (index) => {
    // Remove newly added images
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    // Append each selected file to FormData
    files.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("text", text);

    await axios.post(`${apiStart}/api/${type}/`, formData, {
      withCredentials: true,
    });

    window.location.href = `/admin/${type}`;
  };

  // Map files to the format expected by react-image-gallery
  const galleryImages = files.map((file, index) => ({
    original: URL.createObjectURL(file),
    thumbnail: URL.createObjectURL(file),
    renderItem: () => (
      <div className="d-flex align-items-center justify-content-center image-preview">
        <img
          style={{ width: "450px" }}
          src={URL.createObjectURL(file)}
          alt="Preview"
        />
        <button
          style={{ position: "absolute", bottom: 0 }}
          type="button"
          className="remove-btn py-3 rounded bg-white"
          onClick={() => handleRemoveImage(index)}
        >
          Remove
        </button>
      </div>
    ),
  }));

  return (
    <Container fluid className="home">
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9} className="newContainer">
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              {galleryImages.length > 0 ? (
                <Gallery items={galleryImages} /> // Display images with react-image-gallery
              ) : (
                <img
                  src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  alt="placeholder"
                  className="img-fluid"
                />
              )}
            </div>
            <div className="right">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="formInput">
                  <Form.Label htmlFor="file">
                    Image upload:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </Form.Label>
                  <Form.Control
                    id="file"
                    type="file"
                    name="file"
                    multiple // Enable multiple file selection
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    type="text"
                    name="text"
                    placeholder="Enter product name"
                    required
                  />
                </Form.Group>

                <Button aria-label="add new product" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewType;
