import "./edit.scss";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Sidebar from "../../../components/sidebar/Sidebar";
import useFetch from "../../../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiStart } from "../../../../apiurl/apiData";
import Gallery from "react-image-gallery"; // Import the Gallery component
import "react-image-gallery/styles/css/image-gallery.css"; // Import gallery styles

const EditProduct = () => {
  const categoryOptions = [
    "sofa-sets",
    "beds",
    "table",
    "recliner-sofa",
    "wardrobes",
    "office-table",
    "grandfather-clock",
    "chair-dining-set",
    "book-shelves",
    "coffee-table",
    "carpet",
    "tv-stand",
    "home-decor",
    "lamps-&-lighting",
    "crockery-shelf",
    "bed-side-table",
    "shoe-rack",
    "dressing-tables",
    "modular-kitchen",
    "stool",
    "sitout-chair",
    "diwan",
    "bench",
    "display-unit",
  ];

  const subCategoryOptions = [
    "living",
    "bedroom",
    "dining",
    "storage",
    "kidsroom",
    "decor",
    "furnishing",
    "outdoor",
  ];

  const { id } = useParams();
  const { data, loading, error } = useFetch(`${apiStart}/api/products/${id}`);
  const [files, setFiles] = useState([]);
  const [keptImages, setKeptImages] = useState([]); // Existing images to keep
  const [removedImages, setRemovedImages] = useState([]); // Existing images to delete
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [name, setName] = useState("");

  console.log({
    files: files,
    keptImages: keptImages,
    removedImages: removedImages,
  });

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleRemoveImage = (index, isNew = false) => {
    if (isNew) {
      // Remove newly added images
      const updatedFiles = [...files];
      updatedFiles.splice(index, 1);
      setFiles(updatedFiles);
    } else {
      // Remove existing images
      const removedImage = keptImages[index];
      setRemovedImages([...removedImages, removedImage]);
      const updatedKeptImages = [...keptImages];
      updatedKeptImages.splice(index, 1);
      setKeptImages(updatedKeptImages);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append all selected files to FormData
    files.forEach((file) => {
      formData.append("images", file);
    });

    // Add kept image URLs
    formData.append("keptImages", JSON.stringify(keptImages));

    // Add removed image URLs
    formData.append("removedImages", JSON.stringify(removedImages));

    formData.append("category", category === "" ? data.category : category);
    formData.append(
      "subcategory",
      subCategory === "" ? data.subcategory : subCategory
    );
    formData.append("name", name === "" ? data.name : name);

    await axios.put(`${apiStart}/api/products/file/${id}`, formData, {
      withCredentials: true,
    });

    window.location.href = `/admin/products?category=${data.category}`;
  };

  // Combine existing and new images for rendering
  const galleryImages = [
    ...(keptImages || []).map((image, index) => ({
      original: image,
      thumbnail: image,
      renderItem: () => (
        <div className="d-flex align-items-center justify-content-center image-preview">
          <img style={{ width: "50%" }} src={image} alt="Preview" />
          <Button
            style={{ position: "absolute", bottom: 0 }}
            variant="danger"
            size="sm"
            className="remove-button"
            onClick={() => handleRemoveImage(index)}
          >
            Remove
          </Button>
        </div>
      ),
    })),
    ...files.map((file, index) => ({
      original: URL.createObjectURL(file),
      thumbnail: URL.createObjectURL(file),
      renderItem: () => (
        <div className="d-flex align-items-center justify-content-center image-preview">
          <img
            style={{ width: "50%" }}
            src={URL.createObjectURL(file)}
            alt="Preview"
          />
          <Button
            style={{ position: "absolute", bottom: 0 }}
            variant="danger"
            size="sm"
            className="remove-button"
            onClick={() => handleRemoveImage(index, true)}
          >
            Remove
          </Button>
        </div>
      ),
    })),
  ];

  useEffect(() => {
    setKeptImages(data.images);
  }, [data]);
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <div className="top">
          <h1>Edit Product</h1>
        </div>
        <div className="main-set">
          <div className="gallery-section">
            {galleryImages.length > 0 ? (
              <Gallery items={galleryImages} />
            ) : (
              <img
                className="img-fluid"
                src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                alt="No preview available"
              />
            )}
          </div>

          <div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="formInput">
                <Form.Label htmlFor="file">
                  Image upload:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </Form.Label>
                <Form.Control
                  type="file"
                  id="file"
                  name="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Category:</Form.Label>
                <Form.Select onChange={(e) => setCategory(e.target.value)}>
                  <option selected>{data.category}</option>
                  {categoryOptions
                    .filter((e) => e !== data.category)
                    ?.map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Sub Category:</Form.Label>
                <Form.Select
                  defaultValue={data.subcategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value={data.subcategory} selected>
                    {data.subcategory}
                  </option>
                  {subCategoryOptions
                    .filter((e) => e !== data.subcategory)
                    ?.map((e, i) => (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  onChange={(e) => setName(e.target.value)}
                  defaultValue={data.name}
                  type="text"
                  name="text"
                  placeholder="Enter product name"
                />
              </Form.Group>

              {category === "" &&
              files.length === 0 &&
              subCategory === "" &&
              name === "" ? (
                <Button aria-label="edit product" disabled type="submit">
                  Submit
                </Button>
              ) : (
                <Button type="submit">Submit</Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
