import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Container } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../../components/Loader";
import { useDispatch } from "react-redux";
import {
  addtofavourite,
  removeFromfavourite,
} from "../../../store/Actions/Favourite";
import { apiStart } from "../../../apiurl/apiData";

export default function BestSellers(prop) {
  const dispatch = useDispatch();
  const { data, loading } = useFetch(`${apiStart}/api/bestSeller/`);
  const notify = () => toast(`Added to WishList!`);

  const addToFavHandler = (props, y) => {
    const classactive = y.target.classList.value.includes("active");

    if (classactive) {
      notify("Removed From Wishlist!");
      y.target.classList.remove("active");
      dispatch(
        removeFromfavourite({
          id: props._id,
        })
      );
    } else {
      notify("Added To Wishlist!");
      y.target.classList.add("active");
      dispatch(
        addtofavourite({
          id: props._id,
          image: props.image,
          name: props.name,
        })
      );
    }
  };
  const existingFavItems = JSON.parse(localStorage.getItem("favItems"));

  const slideLeft = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft -= 350;
  };

  const slideRight = () => {
    let slider = document.getElementById("slider");
    slider.scrollLeft += 350;
  };

  return (
    <Container id="bst-anchor" className="bst-container border-bottom">
      <hr />
      <h2 className="with-stroke text-center">
        <span className="stroke-head">{prop.data}</span>
      </h2>
      <div className="bst-seller ">
        <div className="cont-category">
          <img
            src="https://www.svgrepo.com/show/257665/left-arrow-next.svg"
            className="lefttag border img-fluid opacity-50 cursor-pointer hover:opacity-100"
            onClick={slideLeft}
            alt="left"
          />
          <div
            id="slider"
            className="best-align w-full h-full whitespace-nowrap scroll-smooth scrollbar-hide"
          >
            {loading ? (
              <Loader />
            ) : (
              data.map((e) => (
                <div key={e._id}>
                  <Card key={e._id} className="pic-hover">
                    <i
                      id="set-red"
                      onClick={(y) => addToFavHandler(e, y)}
                      className={
                        existingFavItems?.find((y) => y.id === e._id)
                          ? "fa fa-heart icon-fav active"
                          : "fa fa-heart icon-fav "
                      }
                      aria-hidden="true"
                    ></i>{" "}
                    <a
                      aria-label="bestseller"
                      href={`/query/bestseller/${e._id}`}
                      style={{ color: "black" }}
                    >
                      <Card.Img
                        className="bstimgsize"
                        variant="top"
                        src={e.images[0]}
                        alt="best seller"
                      />
                      <Card.Body className="p-0 mt-1">
                        <p>{e.name}</p>
                      </Card.Body>
                    </a>
                  </Card>
                </div>
              ))
            )}
          </div>
          <img
            src="https://www.svgrepo.com/show/5572/right-arrow.svg"
            className="lefttag border img-fluid opacity-50 cursor-pointer hover:opacity-100"
            onClick={slideRight}
            alt="right"
          />
        </div>
      </div>
    </Container>
  );
}
